import React, { createContext, useContext } from "react";
import $reducer from "../../utils/app/$reducer";

const ClubContext: any = createContext(undefined);
export const useClub = () => useContext(ClubContext);

export const ClubProvider = ({ children }: any) => {
  const initState: any = {
    status: "no",
    joinDay: 0,
    endDate: "",
    currentStep: -1,
    memebrTime: "",
    memberName: "",
    expireTime: "",
    memberAvatar: "",
    clickChange: false,
  };
  const [state, dispatch] = $reducer({ ...initState });

  return (
    <ClubContext.Provider value={{ state, dispatch }}>
      {children}
    </ClubContext.Provider>
  );
};
