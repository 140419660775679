import { useEffect, useState } from "react";
import { HashRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import AuthRouter from "./components/authRouter";
import GetRouter from "./components/getRouter";
import $getQuery from "./utils/app/$getQuery";
import VConsole from "vconsole";
import "lib-flexible";
import "./asset/styles/iconfont.css";
import "./asset/styles/public.scss";
import { Mask, Toast } from "antd-mobile";

function App() {
  const $params: any = $getQuery();
  const { groupId = "" } = { ...$params };
  const groupIdStrorage = localStorage.getItem("groupId");
  const groupIdCurrent = groupId || groupIdStrorage;
  if (!groupIdStrorage || (groupId && groupIdStrorage !== groupId)) {
    localStorage.setItem("groupId", groupId);
  }

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    getGlobalConfig();
    const flag = initEnviroment();
    if (flag) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, []);

  const initEnviroment = () => {
    var ua: any = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      return true; // 微信中打开
    } else {
      return false; // 普通浏览器中打开
    }
  };

  const getGlobalConfig = () => {
    switch (groupIdCurrent) {
      case "1":
        document.documentElement.style.setProperty("--themeColor", "#2688ff");
        document.documentElement.style.setProperty(
          "--codeBottomColor",
          "#a6ceff"
        );
        document.documentElement.style.setProperty(
          "--buttonAndTitleColor",
          "#4c9dff"
        );
        document.documentElement.style.setProperty("--logoColor", "#a5dcfb");
        document.documentElement.style.setProperty(
          "--topGradientColor",
          "#a6ceff"
        );
        // document.documentElement.style.setProperty('--corebBckground', url('./asset/imgs/personal_bg2.png'));

        break;
    }
  };
  if (window.location.href.indexOf("debug=") > -1) {
    const vConsole = new VConsole();
  }
  return (
    <div className="App">
      {visible ? (
        <Mask visible={visible} className="before_mask_wraper">
          <img src={require("./asset/imgs/mask_login.png")} alt="" />
          {/* <div className="before_mask" >
          </div> */}
        </Mask>
      ) : (
        <HashRouter>
          <AuthRouter>
            <GetRouter />
          </AuthRouter>
        </HashRouter>
      )}

      {/* <AppRouter /> */}
    </div>
  );
}
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);
