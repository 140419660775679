/**
 * 数据赋值
 * @method $reducer
 * 
 * @example
 * import { $reducer } from '@fusion/manage';
 * 
 * const initState = {
 *     a: 1,
 * }
 * const [state, dispatch] = $reducer({ ...initState });
 * 
 * 更新数据
 * dipatch({ a: 2 })
 */
import { useReducer, useRef, useEffect } from 'react';
import type { Dispatch } from 'react';

export default <S>(initState: S):[S, Dispatch<Partial<S>>] => {
    const stateRef = useRef<boolean>(true);

    useEffect(() => {
        stateRef.current = true;

        return () => {
            stateRef.current = false;
        };
    }, []);

    const [state, dispatch] = useReducer((_state: S, _payload:Partial<S>) => {
        return {
            ..._state,
            ..._payload,
        }
    }, initState);

    const _dispatch = (payload: Partial<S>) => {
        if (stateRef?.current) {
            dispatch(payload);
        }
    };

    return [state, _dispatch];
}