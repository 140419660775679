import React, { createContext, useContext, useState } from "react";
import $fetch from "../../utils/app/$fetch";
import { JSONStringify } from "lib/tool";

const CountdownContext: any = createContext(undefined);
export const useCountdown = () => useContext(CountdownContext);

export const MemberProvider = ({ children }: any) => {
  const [countdowns, setCountdowns] = useState(() => {
    // 从 localStorage 恢复状态
    const savedCountdowns: any = localStorage.getItem("countdowns");

    return savedCountdowns ? JSON.parse(savedCountdowns) : {};
  });
  const [activityList, setActivityList] = useState(() => {
    let savedActivityList: any = localStorage.getItem("activityList");
    savedActivityList = JSON.parse(savedActivityList);
    return savedActivityList ? savedActivityList : [];
  });


  const updateActivityList = async (loading = true) => {
    const activityRes: any = await $fetch(
      "event/specialEvent",
      {},
      { loading }
    );
    const activity: any = activityRes.filter(
      (item: any) => item?.eventStatus === "1"
    );
    setActivityList(activity);
  };

  const startCountdown = async (
    eventId: any,
    memberId: any,
    customerId: any,
    timingDuration: any,
    number: any,
    goodsId: any,
    goodsName: any,
    goodsNameShow: any,
    numberShow: any,
  ) => {
    const id = `${eventId}_${memberId}`;
    const endTime = Date.now() + timingDuration * number * 1000; // 例如：   3分钟倒计时
    // const endTime = Date.now() + 10 *  1000; // 例如：   3分钟倒计时

    const showOther = { memberId, customerId, goodsName, number, timingDuration, goodsNameShow,numberShow };
    try {
      // 先核销券码
      await $fetch(
        `/operation/verifyTicket/${customerId}/${goodsId}?eventId=${eventId}&memberId=${memberId}&num=${number}`,
        {},
        { method: "post", noGroupId: "no" }
      );


      setCountdowns((prev: any) => {
        const newCountdowns = {
          ...prev,
          [id]: { endTime, ...showOther },
        };
        localStorage.setItem('countdowns',JSON.stringify(newCountdowns))
        return newCountdowns;
      });
      return true;
    } catch (error) {
      setCountdowns((prev: any) => {
        const newCountdowns = {
          ...prev,
          [id]: { endTime: 0, ...showOther },
        };
        localStorage.setItem('countdowns',JSON.stringify(newCountdowns))
        return newCountdowns;
      });
      console.log(error, "_____error");
      return false;
    }
  };

  const stopCountdown = async (eventId: any, memberId: any) => {
    try {
      const id = `${eventId}_${memberId}`;
      const customerId = countdowns[id]?.customerId;
      await $fetch(
        `/operation/deleteLine/${customerId}/${memberId}?eventId=${eventId}`,
        {},
        { loading: false, method: "post", noGroupId: "no" }
      );
      const rest = countdowns[id];
      setCountdowns((prev: any) => {
        const newCountdowns = {
          ...prev,
          [id]: { ...rest, endTime: 0, visible: true, end: true },
        };
        localStorage.setItem('countdowns',JSON.stringify(newCountdowns))
        return newCountdowns;
      });
      updateActivityList();
    } catch (error) {
      console.log(error, "____error");
    }
  };

  return (
    <CountdownContext.Provider
      value={{
        countdowns,
        setCountdowns,
        activityList,
        updateActivityList,
        startCountdown,
        stopCountdown,
      }}
    >
      {children}
    </CountdownContext.Provider>
  );
};
