/**
 * toast提示
 * @method $toast
 *
 * @param {string|object} params 输入参数
 *     @param {string} params.message  提示文本
 *     @param {number} params.duration 提示显示时长（毫秒）默认1500
 *
 * @example
 * import { $loading } from '@fusion/manage';
 * 
 * $loading.show();
 * $loading.hide();
 */
import ReactDOM from 'react-dom';
import { DotLoading } from 'antd-mobile'
import './style.scss';

class Loading {
    createContainer() {
        let container = document.getElementById('__fusion_widgets');
        if (!container) {
            container = document.createElement('div');
            container.id = '__fusion_widgets';
            document.body.appendChild(container);
        }
        return container;
    }

    show (timeout?: number) {
        const container = this.createContainer();

        const config = {
            tip: '数据加载中...',
        }

        if (timeout) {
            window.setTimeout(() => {
                ReactDOM.unmountComponentAtNode(container as HTMLElement);
            }, timeout);
        }

        // const root = ReactDOM.createRoot(document.getElementById('__fusion_widgets'))
        // root.render(
        //     <div className="__fusion_manage_loading">
        //         <div><span>数据加载中</span><DotLoading  /></div>
        //     </div>
        // )
        ReactDOM.render(
            <div className="__fusion_manage_loading">
                <div><span>数据加载中</span><DotLoading  /></div>
            </div>,
            document.getElementById('__fusion_widgets')
        );
    }

    hide () {
        const container = this.createContainer();
        ReactDOM.unmountComponentAtNode(container as HTMLElement);
    }
}

export default new Loading();
