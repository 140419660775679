import { Toast } from "antd-mobile";
import axios from "axios";
import $loading from "../ui/Loading";
// import $getQuery from "./$getQuery";

// const $params: any = $getQuery();
// const { groupId = "" } = { ...$params };

// const currentGroupId = localStorage.getItem("groupId");
// if (!currentGroupId || (groupId && currentGroupId !== groupId)) {
//   localStorage.setItem("groupId", groupId);
// }

export default function (
  url: string = "",
  params: Record<string, any> = {},
  extend: Record<string, any> = {}
) {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("token");
    const opts: any = {
      url,
      method: (extend && extend["method"]) || "get",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${token}`,
      },
      baseURL: "https://www.ifestive365.com:8085/",
      // baseURL: "http://47.101.149.72:7075/",
      data: { ...params },
      timeout: 30000,
      responseType: "json",
      responseEncoding: "utf8",
      maxContentLength: 2000,
    };

    if (extend) {
      if (extend?.dataType === "form") {
        opts.headers["Content-Type"] = "multipart/form-data";
      }
    }

    const exts: any = {
      toast: extend && extend.toast === false ? false : true, // 是否需要toast，默认有toast
      loading: extend && extend.loading === false ? false : true, // 是否需要loading，默认有loading
      needLogin: extend && extend.needLogin === false ? false : true, // 是否需要登录，默认需求
      needError: extend && extend.needError === false ? false : true,
    };

    const groupId = localStorage.getItem("groupId") || "";
    if (opts.method.toLowerCase() === "post") {
      if (extend.noGroupId === "no") {
        if (params.constructor === Array || extend?.dataType === "form") {
          opts.data = params;
        } else {
          opts.data = { ...params };
        }
      } else {
        opts.data = { ...params, groupId };
      }
    }
    if (opts.method.toLowerCase() === "get") {
      if (extend.noGroupId === "no") {
        opts.params = { ...params };
      } else {
        opts.params = { ...params, groupId };
      }
    }

    if (exts.loading) $loading.show();

    axios({ ...opts })
      .then((res: any) => {
        if (exts?.loading) $loading.hide();
        if (res?.status === 200) {
          if (res?.data.code === 200) {
            resolve(res.data.data);
          } else if (exts.needLogin && res?.data?.code === 401) {
            localStorage.removeItem("token");
            window.location.replace("/#/login");
            return;
          } else if (res?.data.code === 500) {
            if (exts?.toast) {
              if (exts?.loading) $loading.hide();
              Toast.show(res?.data?.message);
              reject(res.data);
            }
          } else {
            resolve(res.data);
          }
        }
      })
      .catch((err: any) => {
        console.log(err, "____________");

        if (exts?.loading) $loading.hide();
        if (exts.toast) {
          Toast.show({
            icon: "fail",
            content: err.message || "系统打酱油了，请稍后重试！",
            // duration:200000
          });
        }
        reject(err);
      });
  });
}
