// import React from "react";
// import { Navigate, useLocation } from "react-router-dom";
// import matchRouter from "./matchRouter";
// import $getQuery from "../utils/app/$getQuery";

// export default function AuthRouter({ children }) {
//   const { groupId } = $getQuery();
//   const location = useLocation();
//   const token = localStorage.getItem("token");
//   const isLogin = token && token !== "undefined";
//   const currentPath = location.pathname;
//   console.log(currentPath, "______currentPath");

//   // Define route conditions
//   const isPublicRoute = ["/login", "/home", "/404"].includes(currentPath);

//   const findPath = matchRouter(currentPath);

//   // Handle public routes
//   if (isPublicRoute) {
//     if (!isLogin && currentPath === "/home") {
//       return <Navigate to={`/login?groupId=${groupId}`} />;
//     }
//     if (isLogin && currentPath === "/login") {
//       return <Navigate to="/home" />;
//     }
//     return children;
//   }

//   // Handle protected routes
//   if (!isLogin) {
//     return <Navigate to="/login" />;
//   }

//   if (isLogin && !findPath) {
//     return <Navigate to="/404" />;
//   }

//   // Render children for valid paths
//   return children;
// }


import React, { useState, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import $getQuery from "../utils/app/$getQuery";

export default function AuthRouter({ children }) {
  const roleType = localStorage.getItem("roleType") || '';
  const { groupId } = $getQuery();
  const groupIdStrorage = localStorage.getItem("groupId");
  const groupIdCurrent = groupId || groupIdStrorage;
  const location = useLocation();
  const token = localStorage.getItem("token");
  const isLogin = token && token !== "undefined";
  const currentPath = location.pathname;

  console.log(currentPath,'____currentPath');

  // 只判断是否为运营页面，运营页面路径包含 "activity"
  const isOperatorRoute = currentPath.includes("/activity");

  // 定义公共页面
  const isPublicRoute = ["/login", "/404", "/error"].includes(currentPath);

  // 1. 如果没有登录，且不是公共页面，跳转到登录页面
  if (!isLogin && !isPublicRoute) {
    return <Navigate to={`/login?groupId=${groupIdCurrent}`} />;
  }

  // 2. 如果已经登录，并且角色信息已获取
  if (isLogin && roleType) {
    // 前台用户只能访问非运营页面
    if (roleType === 'user') {
      if (isOperatorRoute) {
        return <Navigate to={`/error?roleType=${roleType}`} />;
      }
    }
    // 运营角色只能访问运营页面
    else if (roleType === 'operator') {
      if (!isOperatorRoute && !isPublicRoute) {
        return <Navigate to={`/error?roleType=${roleType}`} />;
      }
    }

    // 如果是登录页面，已登录用户重定向到对应角色的主页
    if (currentPath === "/login") {
      return roleType === "user" ? (
        <Navigate to="/home" />
      ) : (
        <Navigate to="/activity/list" />
      );
    }

    // 如果是合法的路由并且有权限，正常渲染子组件
    return children;
  }

  // 3. 如果找不到匹配的路由，跳转到404
  return isPublicRoute ? children : <Navigate to="/404" />;
}
